<script>
	import GlobalVue from '../../helper/Global.vue'
	import Gen from '../../helper/Gen'
	import swal from 'sweetalert'

	export default {
		extends: GlobalVue,
		data() {
			return {
				input: {},
			}
		},
		computed: {},
		mounted() {
			this.$emit("ready", this)
		},
		methods: {
			open(params) {
				global.Modal = this
				$.magnificPopup.open({
					items: {
						src: "#ModalCampaign"
					},
					type: 'inline',
					closeOnContentClick: false,
					closeBtnInside: true,
					closeOnBgClick: false,
					enableEscapeKey: false,
					fiexContentPos: false,
					showCloseBtn: false,
					overflowY: 'scroll',
				})
				$('label.error').remove()
				this.input = {}
				if (params) this.input = params.data
			},
			onSubmit(e) {
				if (e && e.btnLoading()) return;
				Gen.apirest("/submit-campaign", this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) return swal(err.resp.message, '', 'warning')
					swal(resp.message, '', 'success')
					Gen.putStorage("campaign" + this.$route.path, resp.id)
					$.magnificPopup.close()
				}, 'POST')
			},
		},
		watch: {}
	}
</script>

<template>
	<!-- Pilih Desain-->
	<div class="modal1 mfp-hide subscribe-widget" id="ModalCampaign">
		<div :class="'campComp '+(input.ac_is_background=='Y' ? input.ac_image_overlay=='Y'?'campComp--wOverlay':'' : 'campComp--wBgColor')"
			:style="(input.ac_is_background=='Y'?'background-image:url('+uploader(input.ac_image)+');'+(input.ac_image_overlay=='Y'?';--bgOpacity:'+input.ac_background_opacity/100:''):'--bgColor:'+input.ac_background_color+';--bgOpacity:'+input.ac_background_opacity/100)">
			<button class="close-button mfp-close"><i class="icon-line-cross"></i></button>
			<div class="modCampaign">
				<div class="modCampaign__title">
					<template v-if="input.ac_title_type=='image'">
						<div class="modCampaign__banner" v-if="input.ac_title_image_type=='banner'">
							<img :src="uploader(input.ac_title)" alt="">
						</div>
						<img v-else :src="uploader(input.ac_title)" />
					</template>
					<h3 v-else :style="'color:'+input.ac_title_color+';font-family:'+input.ac_title_style+';font-size:'+input.ac_title_size+'px;'+(input.ac_title_style=='Poppins' ? 'font-weight:600;':'')"> {{input.ac_title}}
					</h3>
					<span class="modCampaign__subheading"
						:style="'color:'+input.ac_sub_title_color+';font-family:'+input.ac_sub_title_style+';font-size:'+input.ac_sub_title_size+'px;'+(input.ac_sub_title_style=='Poppins' ? 'font-weight:600;':'')">{{input.ac_sub_title}}</span>
				</div>
				<div class="modCampaign__desc" :style="'color:'+input.ac_description_color+';'" v-html="input.ac_description">
				</div>
				<div v-if="input.ac_is_form=='Y'"
					:class="'modCampaign__form '+(input.ac_form_contrast=='white-bg'?'modCampaign__form--wInputBg':input.ac_form_contrast=='light'?'modCampaign__form--lightField':'')">
					<VForm @resp="onSubmit">
						<div class="row justify-content-center">
							<div class="col-md-8">
								<div class="form-group" v-for="(v,k) in input.ac_form" :key="k">
									<textarea v-if="v.type=='textarea'" :required="v.required=='Y'" rows="4" v-model="v.value"
										:name="v.field" class="frm_custom_sign" :placeholder="v.name"></textarea>

									<input v-else-if="v.type=='alpha'" onkeydown="return alphaOnly(event)" type="text"
										:required="v.required=='Y'" v-model="v.value" :name="v.field" class="frm_custom_sign"
										:placeholder="v.name">

									<input v-else-if="v.type=='numeric'" onkeydown="return numberKey(event)" type="number"
										:required="v.required=='Y'" v-model="v.value" :name="v.field" class="frm_custom_sign"
										:placeholder="v.name">

									<input v-else-if="v.type=='alphanumeric'" onkeydown="return alphaNumber(event)" type="text"
										:required="v.required=='Y'" v-model="v.value" :name="v.field" class="frm_custom_sign"
										:placeholder="v.name">

									<input v-else-if="v.type=='email'" onkeydown="return emailKey(event)" :type="v.type"
										:required="v.required=='Y'" v-model="v.value" :name="v.field" class="frm_custom_sign"
										:placeholder="v.name">

									<input v-else-if="v.type=='phone'" onkeydown="return mobileKey(event)" type="text"
										:required="v.required=='Y'" v-model="v.value" :name="v.field" class="frm_custom_sign"
										:placeholder="v.name">

									<InputCurrency v-else-if="v.type=='currency'" :name="v.field" class="frm_custom_sign"
										v-model="v.value" :required="v.required=='Y'" :placeholder="v.name"></InputCurrency>

									<input v-else :type="v.type" :required="v.required=='Y'" v-model="v.value" :name="v.field"
										class="frm_custom_sign" :placeholder="v.name">

									<LabelError :name="v.field"></LabelError>
								</div>
							</div>
						</div>
						<p class="mb-0 text-center">
							<button type="submit" class="btn_line btn-loading">{{input.ac_button_label}}</button>
						</p>
					</VForm>
				</div>
			</div>
		</div>
	</div>
</template>